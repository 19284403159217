<template>
    <div class="main-container">

        <Header />

        <Breadcrumb :items="items" title="Cooperate Social Responsibility" />

        <CSRSection />

        <!-- <Testimonial /> -->

        <BrandCarousel />

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import CSRSection from '../components/sections/CSRSection'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

    import data from '../data/about.json'
    export default {
        components: {
            Header,
            Breadcrumb,
            CSRSection,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                data,
            }
        },
        metaInfo: {
            title: 'STS CAMBODIA - Cooperate Social Responsibility',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

