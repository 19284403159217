<template>
    <div class="main-container">

        <Header />

        <Breadcrumb :items="items" title="Privacy Policy" />

        <Policy :policyData="data.policy"/>


        <BrandCarousel />

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import data from '../data/about.json'
    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import Policy from '../components/Policy'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';

    export default {
        components: {
            Header,
            Breadcrumb,
            Policy,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                data,
            }
        },
        metaInfo: {
            title: 'STS CAMBODIA - Privacy Policy',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

