<template>
    <div class="google-map-area section" id="support">
        <!-- <div class="maps">
            <a title="Click to view on google maps." target="_blank" href="https://www.google.com/maps/place/%E1%9E%A2%E1%9F%81%E1%9E%9F%E2%80%8B%E1%9E%92%E1%9E%B8%E2%80%8B%E1%9E%A2%E1%9F%81%E1%9E%9F+(%E1%9E%81%E1%9F%81%E1%9E%98%E1%9E%94%E1%9E%BC%E1%9E%8C%E1%9E%B6)+%E1%9E%81%E1%9E%BC%E2%80%8B%E1%9E%A2%E1%9E%B7%E1%9E%9B%E1%9E%92%E1%9E%B8%E1%9E%8C%E1%9E%B8/@11.5855758,104.9125888,18z/data=!4m5!3m4!1s0x310951605a25edf9:0xf2c0970caaf62931!8m2!3d11.5855823!4d104.9134797"><img style="width: 100%; height: 100%" src="assets/img/maps/map.png"></a>
        </div> -->
        <GmapMap
            :center="center"
            :zoom="18"
            map-type-id="terrain"
        >
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
                ></GmapMarker>
        </GmapMap>
    </div>
</template>

<script>
    const place = {lat:11.585594966554437, lng:104.9134807837659}
    // const place = {lat:13.37729384985599, lng:103.81933902320577}
    export default {
        name: 'GoogleMap',
        data() {
            return {
                markers: [{
                    position: place
                }],
                center: place
            }
        },
    }
</script>
<style>
.btn-maper {
    border-radius: 5px;
    margin-bottom: 5px;
    border: none;
    background: #f0542d;
    color: #fff;
    height: 40px;
    outline: none;
}
.btn-maper:hover {
    background: #064789;
}
</style>
