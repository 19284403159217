<template>
    <div class="main-container section-space--inner--100">
        <div class="project-section">
        <div class="container">
            <div class="section-title-area text-center">
                <h2 class="section-title section-space--bottom--50">
                    Cooperate Social Responsibility
                    <br><i class="fas fa-chevron-down"></i>
                </h2>
            </div>
            <div class="col align-items-center">
                <div class="col-12 mb-5">
                    <div class="font-family">
                        <span>{{ data.csr_001.title }}</span>
                    </div>
                </div>
                <div class="col-12 mb-5 section-space--bottom--100">
                    <div class="about-image-two">
                        <img :src="data.csr_001.image" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-12 mb-10">
                <div class="font-family">
                    <span>{{ data.csr_002.title }}</span>
                </div>
            </div>
            <div class="col align-items-center">
                <div class="col-12 mt-5 section-space--bottom--10">
                    <div class="about-image-two">
                        <img :src="data.csr_002.image" alt="image">
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    import data from '../../data/csr.json'
    export default {
        data () {
            return {
                data
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            }, 
            // goToContact() {
            //     this.$router.push('/contact');
            // }
        }
    };
</script>
<style>
.font-family {
    font-family: 'Poppins', serif;
    font-size: 18px;
}
</style>