<template>
    <div class="page-wrapper section-space--inner--120">
        <!--Contact section start-->
        <div class="conact-section">
            <div class="container">

                <div class="row section-space--bottom--50">
                    <div class="col">
                        <!-- google map component -->
                        <GoogleMap />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-12">
                        <div class="contact-information">
                            <h3>{{ data.contactInfo.title }}</h3>
                            <ul>
                                <li>
                                    <span class="icon">
                                        <i :class="data.contactInfo.hotline.icon"></i>
                                    </span>
                                    <span class="text">
                                        <a href="tel:85581716716">{{ data.contactInfo.hotline.no_2 }}</a>
                                    </span>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i :class="data.contactInfo.telephone.icon"></i>
                                    </span>
                                    <span class="text">
                                        <a href="tel:85523722733">{{ data.contactInfo.telephone.no_1 }}</a>
                                    </span>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i :class="data.contactInfo.facebook.icon"></i>
                                    </span>
                                    <span class="text">
                                        <a target="_blank" href="https://bit.ly/3nCrDll">{{ data.contactInfo.facebook.url_1 }}</a>
                                    </span>
                                </li>
                                <!-- <li>
                                    <span class="icon">
                                        <i :class="data.contactInfo.telegram.icon"></i>
                                    </span>
                                    <span class="text">
                                        <a target="_blank" href="https://www.telegram.org/">{{ data.contactInfo.telegram.url_1 }}</a>
                                    </span>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i :class="data.contactInfo.whatsapp.icon"></i>
                                    </span>
                                    <span class="text">
                                        <a target="_blank" href="https://www.whatsapp.com/">{{ data.contactInfo.whatsapp.url_1 }}</a>
                                    </span>
                                </li> -->
                                <li>
                                    <span class="icon"><i :class="data.contactInfo.mail.icon"></i></span>
                                    <span class="text">
                                        <a href="mailto:info@stscambodia.com.kh">{{ data.contactInfo.mail.email }}</a>
                                    </span>
                                </li>
                                <li>
                                    <span class="icon"><i :class="data.contactInfo.web.icon"></i></span>
                                    <span class="text">
                                        <a href="#">{{ data.contactInfo.web.web }}</a>
                                    </span>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i :class="data.contactInfo.address.icon"></i>
                                    </span>
                                    <span class="text">
                                        {{ data.contactInfo.address.location }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8 col-12">
                        <div class="contact-form">
                            <h3>Leave Your Message</h3>
                            <form id="contact-form" @submit.prevent="sendEmail">
                                <div class="row row-10">
                                    <div class="col-md-6 col-12 section-space--bottom--20">
                                        <input required v-model="user_name" name="user_name" type="text" placeholder="Your Name">
                                    </div>
                                    <div class="col-md-6 col-12 section-space--bottom--20">
                                        <input required v-model="user_email" name="user_email" type="email" placeholder="Your Email">
                                    </div>
                                    <div class="col-12">
                                        <textarea required v-model="message" name="message" placeholder="Your Message"></textarea>
                                    </div>
                                    <div class="form-result col-12">
                                        <!-- <p class="alert alert-success" v-if="success && !error">Message sent successfully. Thank you!</p>
                                        <p class="alert alert-error" v-if="!success && error">Message failed.</p> -->
                                        <b-alert
                                            :show="dismissCountDown"
                                            fade
                                            variant="success"
                                            @dismiss-count-down="countDownChanged"
                                            >
                                            Message sent successfully. Thank you!
                                        </b-alert>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--Contact section end-->
    </div>
</template>

<script>
    import emailjs from 'emailjs-com';
    import data from '../../data/contact.json'
    import GoogleMap from '@/components/GoogleMap';
    export default {
        name: 'ContactForm',
        components: {
            GoogleMap,
        },
        data () {
            return {
                contact_notice: '',
                data,
                user_name: '',
                user_email: '',
                message: '',
                success: false,
                error: false,
                dismissSecs: 8,
                dismissCountDown: 0,
            }
        },
        methods: {
            sendEmail(e, variant = 'success') {
                try {
                    //Use Email Js need to fill this information(Service_ID: thepha_550, Template ID: template_ub5lwa6, User ID: user_FfZ4DBXMDFE8dKPx1L4ZO)
                    emailjs.sendForm('admin_web_id', 'template_nsohu8v', e.target,'user_REyXXEOwg4TZuA9Hw82Gv', {
                        user_name: this.user_name,
                        user_email: this.user_email,
                        message: this.message
                    })
                    this.dismissCountDown = this.dismissSecs
                } catch(error) {
                    console.log({error})
                }
                // Success Message
                this.success = true,
                // Reset form field
                this.user_name = ''
                this.user_email = ''
                this.message = ''
            },
        },
    };
</script>