<template>
    <div class="feature-section section-space--inner--120 grey-bg">
        <div class="container">
            <div class="col-lg-12">
                <div class="feature-item-wrapper">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12 section-space--bottom--30" v-for="policy in policyData" :key="policy.id">
                            <div class="feature">
                                <div class="icon">
                                    <img :src="policy.icon" class="img-fluid" alt="icon">
                                </div>
                                <div class="content">
                                    <h3>{{ policy.title }}</h3>
                                    <p>{{ policy.body }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- <CoolLightBox 
        :items="items" 
        :index="index"
        @close="index = null">
        </CoolLightBox>

        <div class="images-wrapper">
        <div
            class="image"
            v-for="(image, imageIndex) in items"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image + ')' }"
        ></div> -->
        </div>
    </div>
</template>

<script>
    export default {
        props: ['policyData'],
        data() {

        return {
            items: [
                'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/nature-quotes-1557340276.jpg?crop=0.666xw:1.00xh;0.168xw,0&resize=640:*',
            ],
            index: null,
        }
        },
    };
</script>